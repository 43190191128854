<template>
  <section class="our-stores">
    <div class="container">
      <span class="section-heading font-weight-normal text-center d-block"
        >Onze winkel</span
      >
      <div v-if="storeImages.length > 1" class="row">
        <div
          v-for="(storeImage, index) in storeImages"
          :key="`stores-${index}`"
          class="col-12 col-md-6"
        >
          <b-link :to="`/winkel/` + storeImage.url_key">
            <div
              v-if="storeImage.base_image != null"
              :style="{ backgroundImage: `url(${storeImage.base_image.url})` }"
              class="img-holder"
            ></div>
          </b-link>
        </div>
      </div>

      <div v-else class="row">
        <div
          v-for="(images, imgindex) in storeImages[0].images"
          :key="`stores-${imgindex}`"
          class="col-12 col-md-6"
        >
          <b-link :to="`/winkel/` + storeImages[0].url_key">
            <div
              v-if="images.url != null"
              :style="{ backgroundImage: `url(${images.url})` }"
              class="img-holder"
            ></div>
          </b-link>
        </div>
      </div>
    </div>
  </section>
</template>
t
<script>
export default {
  name: "OurStores",
  data: () => ({}),
  computed: {
    storeImages() {
      return this.$store.getters["stores/getStores"];
    },
  },
};
</script>
